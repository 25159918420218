
import { modalController, IonInput } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  name: "ChangePassword",
  components: {
    IonInput,
  },
  data: () => {
    return {
      store: useStore(),
      newPassword: "",
      confirmPassword: "",
      loading: false,
      errors: {},
    };
  },
  methods: {
    async changePassword() {
      const data = new FormData();
      data.append("password", this.newPassword);
      data.append("password_confirmation", this.confirmPassword);
      try {
        // dispatch
        this.loading = true;
        this.errors = {};
        const res = await this.store.dispatch("changePassword", data);
        if (res === 200) {
          this.newPassword = "";
          this.confirmPassword = "";
          this.close();
        }
      } catch (e) {
        this.errors = e.response.data.error;
      }
      this.loading = false;
    },
    close() {
      modalController.dismiss();
    },
  },
});
