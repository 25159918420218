
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol, IonInput, modalController } from "@ionic/vue";
import { useStore } from "../store";
import ChangePassword from "../components/ChangePassword.vue";

export default defineComponent({
  name: "AccountDetails",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
  },
  data: () => {
    return {
      store: useStore(),
      loading: false,
      errors: {},
    };
  },
  computed: {
    user(): {} {
      return this.store.state.user;
    },
  },
  methods: {
    calendarFocus(e: any) {
      e.target.type = "date";
    },
    logout() {
      this.store.dispatch("logout").then(() => {
        this.$router.push({ path: "/" });
      });
    },
    async updateUser() {
      this.loading = true;
      this.errors = {};
      try {
        await this.store.dispatch("updateUser");
        // console.log("update user");
      } catch (e) {
        console.log("catch error on update user");
        console.log(e.response);
        this.errors = e.response.data;
      }
      this.loading = false;
    },
    loginFacebook() {
      if (this.store !== null) {
        this.store.dispatch("loginFacebook").then((res) => {
          if (res === "login") {
            // Success!
            alert("Successfully connected your Facebook account");
          }
          if (res === "signUp") {
            // Success!
            alert("You have already connected your google account");
          }

          // TODO: check failure
        });
      } else {
        alert("no store found");
      }
    },
    loginGoogle() {
      if (this.store !== null) {
        this.store.dispatch("loginGoogle").then((res) => {
          // console.log(res);
          if (res === "login") {
            // Success!
            alert("Successfully connected your Google account");
          }
          if (res === "signUp") {
            // Success!
            alert("You have already connected your google account");
          }

          // TODO: check failure
        });
      } else {
        alert("no store found");
      }
    },
    async changePassword() {
      const modal = await modalController.create({
        component: ChangePassword,
        cssClass: "change-password-modal",
      });
      return modal.present();
    },
  },
});
